<template>
  <a-menu
    class="side-menu-style-class"
    v-model:openKeys="openKeys"
    v-model:selectedKeys="selectedKeys"
    :mode="mode"
    :theme="darkMode ? 'dark' : 'light'"
  >
    <a-menu-item @click="toggleCollapsed" key="dashboard">
      <router-link to="/portal/dashboard">
        <sdFeatherIcons type="home" />
        <span> Dashboard </span>
      </router-link>
    </a-menu-item>

    <a-menu-item @click="toggleCollapsed" key="campaign">
      <router-link to="/portal/campaigns">
        <sdFeatherIcons type="play" />
        <span> Campaigns </span>
      </router-link>
    </a-menu-item>

    <a-menu-item @click="toggleCollapsed" key="participants">
      <router-link to="/portal/participants">
        <sdFeatherIcons type="users" />
        <span> Participants </span>
      </router-link>
    </a-menu-item>

    <a-menu-item @click="toggleCollapsed" key="prizes">
      <router-link to="/portal/prizes">
        <sdFeatherIcons type="gift" />
        <span> Gift and prize </span>
      </router-link>
    </a-menu-item>

  </a-menu>
</template>
<script>
import { computed, reactive, ref, toRefs, watch, watchEffect } from 'vue';
import VueTypes from 'vue-types';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

export default {
  name: 'AsideItems',
  props: {
    toggleCollapsed: VueTypes.func,
    events: VueTypes.object,
  },
  setup(props) {
    const store = useStore();
    const darkMode = computed(() => store.state.themeLayout.data);
    const mode = ref('inline');
    const { events } = toRefs(props);
    const {
      onRtlChange,
      onLtrChange,
      modeChangeDark,
      modeChangeLight,
      modeChangeTopNav,
      modeChangeSideNav,
    } = events.value;

    const router = computed(() => useRoute());
    const state = reactive({
      selectedKeys: ['home'],
      openKeys: ['dashboard'],
      preOpenKeys: ['dashboard'],
    });

    watchEffect(() => {
      if (router.value.matched.length) {
        if (router.value.matched.length > 2) {
          state.selectedKeys = [router.value.matched[2]?.name];
          state.openKeys = [router.value.matched[1]?.name];
          state.preOpenKeys = [router.value.matched[1]?.name];
        } else if (router.value.matched.length > 3) {
          state.selectedKeys = [router.value.matched[3]?.name];
          state.openKeys = [router.value.matched[1]?.name];
          state.preOpenKeys = [router.value.matched[1]?.name];
        } else {
          state.selectedKeys = [router.value.matched[1]?.name];
          state.openKeys = [router.value.matched[1]?.name];
          state.preOpenKeys = [router.value.matched[1]?.name];
        }
      }
    });

    watch(
      () => state.openKeys,
      (val, oldVal) => {
        state.preOpenKeys = oldVal;
      },
    );

    return {
      mode,
      ...toRefs(state),
      darkMode,
      onRtlChange,
      onLtrChange,
      modeChangeDark,
      modeChangeLight,
      modeChangeTopNav,
      modeChangeSideNav,
    };
  },
};
</script>
